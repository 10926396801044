import React from "react";
import Layout from "layout";
import Hero from "common/hero";
import Seo from "common/seo";
import { Link } from "gatsby";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";
import heroImageRef from "images/body/hero.webp";
import icon1 from "images/icons/icon-dry-skin-conditon.png";
import icon2 from "images/icons/icon-eczema-condition.png";
import icon3 from "images/icons/icon-damaged-skin-condition.png";
import icon4 from "images/icons/icon-itch-condition.png";
import img1 from "images/callouts/cout-footer-resources.webp";
import img2 from "images/callouts/cout-footer-baby.webp";
//import img3 from "images/callouts/sunproducts2x.webp";
import img3 from "images/callouts/sunproducts2x.webp";
import "./body.scss";

function BodyPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-body"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Body"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic body care solutions for dry skin, eczema, damaged skin, and itch relief"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <h2>What are your patients experiencing?</h2>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <ColoredBox
                imgRef={icon1}
                imgAlt="DRY SKIN"
                headText="DRY SKIN"
                bodyText="Explore hydration"
                boxLink="/body/dryskin"
                headBgColor="#6ACAD5"
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <ColoredBox
                imgRef={icon2}
                imgAlt="ECZEMA"
                headText="ECZEMA"
                bodyText="Explore regimen"
                boxLink="/body/eczema"
                headBgColor="#A70531"
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <ColoredBox
                imgRef={icon3}
                imgAlt="Damaged skin"
                headText="Damaged skin"
                bodyText="Explore formulation"
                boxLink="/body/damagedskin"
                headBgColor="#003E7E"
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <ColoredBox
                imgRef={icon4}
                imgAlt="Itch"
                headText="Itch"
                bodyText="Explore relief"
                boxLink="/body/itch"
                headBgColor="#92A9CC"
              />
            </Col>
          </Row>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img1}
                  imgAlt="Browse resources"
                  caption="Find resources to support your skin care recommendations"
                  buttonText="Browse resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img2}
                  imgAlt="Explore Baby Care"
                  caption="Soothe babies’ irritated skin from top to bottom"
                  buttonText="Explore Baby Care"
                  buttonUrl="/baby"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Discover Sun Care"
                  caption="Sun protection for all skin types and tones"
                  buttonText="Discover Sun Care"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default BodyPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef}>
    <div className="inner-hero-content">
      <h1>
        Approach body skin care with clinically proven, therapeutic solutions
      </h1>
      <p className="hero-reg-text">
        Eucerin<sup>&reg;</sup> and Aquaphor<sup>&reg;</sup> offer a range of
        therapeutic body care solutions for dry skin, eczema, damaged skin, and
        itch. Explore the conditions below to understand how our products are
        designed to help.
      </p>
    </div>
  </Hero>
);

const ColoredBox = ({
  imgRef,
  imgAlt,
  headText,
  bodyText,
  boxLink,
  headBgColor,
}) => (
  <div className="colored-box">
    <img src={imgRef} alt={imgAlt || ""} />
    <h3 style={{ backgroundColor: headBgColor }}>{headText}</h3>
    <Link to={boxLink}>
      <p className="box-text">{bodyText}</p>
    </Link>
  </div>
);
